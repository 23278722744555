@font-face {
  font-family: 'Animosa';
  src: url('/fonts/animosa/animosa-extrabold-webfont.woff2') format('woff2'),
    url('/fonts/animosa/animosa-extrabold-webfont.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: 'Animosa';
  src: url('/fonts/animosa/animosa-bold-webfont.woff2') format('woff2'),
    url('/fonts/animosa/animosa-bold-webfont.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Animosa';
  src: url('/fonts/animosa/animosa-regular-webfont.woff2') format('woff2'),
    url('/fonts/animosa/animosa-regular-webfont.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Animosa';
  src: url('/fonts/animosa/animosa-light-webfont.woff2') format('woff2'),
    url('/fonts/animosa/animosa-light-webfont.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Animosa';
  src: url('/fonts/animosa/animosa-extralight-webfont.woff2') format('woff2'),
    url('/fonts/animosa/animosa-extralight-webfont.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}
